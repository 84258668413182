import {IToast} from '@pxhub/shared-components-v2/dist/Toast'
import React, {ChangeEvent, useEffect, useState} from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import SurveyDetails from '../components/_pages/SurveyDetails'
import ToastView from '../components/ToastView'
import EnterSurveyCode from '../components/_pages/EnterSurveyCode'
import Logo from '../images/logo.png'
import {useTranslation} from 'react-i18next'
import languageCodes from '../utils/languageCodes'

const AppRouter = () => {
  const [toast, setToast] = useState<IToast[] | null>(null)
  const {i18n, t} = useTranslation()
  const [preferredLanguage, setPreferredLanguage] = useState(
    localStorage?.getItem('lng') === 'en-GB' ? 'en' : localStorage?.getItem('lng') || 'en',
  )

  const handleSetToast = (newToast: IToast | null) => {
    if (!newToast) setToast(null)
    else setToast(toast && toast.length >= 1 ? [...toast, newToast] : [newToast])
  }

  const handleLanguage = () => {
    if (window) {
      const hasKey = window.localStorage.getItem('lng')
      if (!hasKey || hasKey === 'en-GB')
        window.localStorage.setItem('lng', i18n.resolvedLanguage === 'en-GB' ? 'en' : i18n.resolvedLanguage || 'en')
    }
  }

  const handleChangeLanuage = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    // Set localStorage to remember preference

    if (e.target.name === 'preferredLanguage') {
      window && window.localStorage.setItem('lng', e.target.value)
      i18n.changeLanguage(e.target.value)
    }
    setPreferredLanguage(e.target.value)
  }

  useEffect(() => {
    handleLanguage()
  }, [])

  return (
    <>
      <header>
        <div className="color-bar"></div>
        <div className="logo">
          <img src={Logo} alt="The People Experience Hub" />
        </div>
        <div className="row end-xs g-8">
          <select name="preferredLanguage" onChange={handleChangeLanuage} value={preferredLanguage}>
            {languageCodes
              .filter(item => ['de', 'en', 'es', 'fi', 'fr', 'it', 'nb_NO', 'zh', 'ro', 'ja'].includes(item.id))
              .map((item, i) => (
                <option value={item.id}>{item.nativeName}</option>
              ))}
          </select>
          <button>
            <a href="https://pxhub.io" rel="noreferrer" target="_blank">
              {t('general.learnMoreButton')}
            </a>
          </button>
        </div>
      </header>

      <div className="app__container">
        {toast?.length ? <ToastView toast={toast} setToast={setToast} /> : null}
        <Router>
          <Routes>
            <Route path="/" element={<EnterSurveyCode />} />
            <Route path="/:surveyCode" element={<SurveyDetails handleSetToast={handleSetToast} />} />
            <Route path="/*" element={<EnterSurveyCode />} />
          </Routes>
        </Router>
      </div>
    </>
  )
}

export default AppRouter
