import axios, {AxiosResponse} from 'axios'
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {ISurveyData, ITranslation} from '../../types'
import RecipientSearchForm from '../RecipientSearchForm'
import queryString from 'query-string'
import {IToast} from '@pxhub/shared-components-v2/dist/Toast'
import GlobalLoading from '../global/GlobalLoading'
import Logo from '../../images/px_logo.png'
import {useTranslation} from 'react-i18next'

const url = `${process.env.REACT_APP_REGISTER_BASE_URL}`

interface IProps {
  handleSetToast: (toast: IToast | null) => void
}

export const SurveyDetails: React.FC<IProps> = ({handleSetToast}: IProps) => {
  const {surveyCode} = useParams() as {surveyCode: string}
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [surveyData, setSurveyData] = useState<ISurveyData | null>(null)
  const [searchData, setSearchData] = useState<Record<string, any> | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [imageError, setImageError] = useState<boolean>(false)

  const {t, i18n} = useTranslation()

  const [currentTranslation, setCurrentTranslation] = useState<ITranslation>()
  const [translations, setTranslations] = useState<ITranslation[]>([])

  const imgPath = surveyData?.companyConfig.logo || 'logo-icon'

  const handleImageError = () => setImageError(true)

  const recipientSearch = async (searchString: string) => {
    // Set toast
    handleSetToast({type: 'LOADING', message: t('toasts.searchingForSurvey')})

    try {
      if (!surveyData) await getDataFromCode(surveyCode)

      if (!searchString) return

      const {data} = (await axios.get(`${url}/${surveyCode}/search?${searchString}`)) as AxiosResponse<{
        linkCode: string
      }>

      try {
        surveyData && window.location.replace(`${surveyData.companyConfig.surveyUrl}/${data.linkCode}`)
      } catch (error) {
        setSearchData(data)
      }
    } catch (error) {
      const userErrorMessage = t('toasts.errorMessage')

      handleSetToast({type: 'INFO', message: userErrorMessage})
    }
  }

  const getDataFromCode = async (inputCode: string) => {
    handleSetToast({type: 'LOADING', message: t('toasts.getSurvey'), timeout: 5000})

    try {
      const {data}: {data: ISurveyData} = await axios.get(`${url}/${inputCode}`)

      if (data.companyConfig.cssSuffix) {
        const newScriptElement = document.createElement('link')
        newScriptElement.id = 'customer-css'
        newScriptElement.setAttribute('rel', 'stylesheet')
        newScriptElement.setAttribute('type', 'text/css')
        newScriptElement.setAttribute('href', `https://cdn.pxhub.io/css/${data.companyConfig.cssSuffix}`)
        document.body.appendChild(newScriptElement)
      }
      handleSetToast(null)

      setSurveyData({...data, surveyCode: inputCode})

      setTranslations(data.translations)

      // Set the current translation here

      const languageCode = localStorage?.getItem('lng') || 'en'

      const findTranslation = data.translations.find(item => item.languageCode === languageCode)

      if (!findTranslation)
        setCurrentTranslation({languageCode: 'en', surveyTitle: data.survey.name, surveyIntro: data.survey.intro_text})
      else
        setCurrentTranslation({
          languageCode: findTranslation.languageCode,
          surveyTitle: findTranslation.surveyTitle,
          surveyIntro: findTranslation.surveyIntro,
        })

      setLoading(false)
    } catch (error) {
      setLoading(false)
      const userErrorMessage = t('toasts.errorMessage')

      handleSetToast({type: 'INFO', message: userErrorMessage})

      navigate('/')
    }
  }
  const handleNotMyCode = () => {
    navigate('/')
  }

  // Example ?104=pxhubtesting%2BErma.Summers-a5519%40gmail.com&105=1975-06-25
  // encodeURIComponent() the values not the whole string

  const handleSearchParams = async () => {
    const params = Object.fromEntries([...searchParams])
    // Check if we have search string in the url
    if (Object.keys(params).length) await recipientSearch(queryString.stringify(params))
    else await getDataFromCode(surveyCode)
  }

  useEffect(() => {
    if (!surveyCode) return navigate('/')
    handleSearchParams()
  }, [])

  useEffect(() => {
    if (!surveyData) return
    const languageCode = localStorage?.getItem('lng') || 'en'

    const findTranslation = translations.find(item => item.languageCode === languageCode)

    if (!findTranslation)
      setCurrentTranslation({
        languageCode: 'en',
        surveyTitle: surveyData?.survey.name,
        surveyIntro: surveyData?.survey.intro_text,
      })
    else
      setCurrentTranslation({
        languageCode: findTranslation.languageCode,
        surveyTitle: findTranslation.surveyTitle,
        surveyIntro: findTranslation.surveyIntro,
      })
  }, [i18n.language])

  return (
    <div className="inner__container demogs">
      {!loading && surveyData ? (
        <>
          {/* // Heading */}
          <div className="col col-no-gutter mb-24">
            <div className="row center-xs mb-8">
              {!imageError ? (
                <img
                  className="intro__logo"
                  src={`https://px-hub-cms.s3.eu-west-2.amazonaws.com/companies/${imgPath}.png`}
                  onError={handleImageError}
                  alt="Company Logo"
                />
              ) : (
                <img className="intro__logo" src={Logo} alt="The People Experience Hub" />
              )}
            </div>
            <div className="row center-xs mb-8">
              <h1>{currentTranslation?.surveyTitle || surveyData.survey.name}</h1>
            </div>
            <div className="row center-xs mb-24">
              <div
                className="text__editor__styles"
                dangerouslySetInnerHTML={{__html: currentTranslation?.surveyIntro || surveyData.survey.intro_text}}
              ></div>
            </div>
          </div>

          {/* // Enter demogs */}
          {!searchData ? (
            <RecipientSearchForm demogFields={surveyData.demogFields} recipientSearch={recipientSearch} />
          ) : null}
          <p onClick={handleNotMyCode} className="p-newcode">
            {t('general.notYourSurvey')}
          </p>
          {!!searchData ? (
            <a
              style={{textDecoration: 'underline'}}
              href={`${surveyData.companyConfig.surveyUrl}/${searchData.linkCode}`}
            >
              Go to survey
            </a>
          ) : null}
        </>
      ) : (
        <GlobalLoading />
      )}
    </div>
  )
}

export default SurveyDetails
